<template>
  <div class="box">
    <div class="logo_box"></div>
    <div class="m-2-1">
      <div class="login_box">
        <div class="ms-title">卫健公社健康管理系统</div>
        <el-form ref="loginForm" :model="loginForm" :rules="rules" label-width="0rem" class="ms-content">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" placeholder="用户名">
              <el-button slot="prepend" icon="el-icon-user"></el-button>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" placeholder="用户密码" v-model="loginForm.password">
              <el-button slot="prepend" icon="el-icon-lock"></el-button>
            </el-input>
          </el-form-item>
          <el-form-item prop="code" v-if="captchaOnOff">
            <el-input v-model="loginForm.code" auto-complete="off" placeholder="验证码" style="width: 63%"
              @keyup.enter.native="handleLogin">
            </el-input>
            <div class="login-code">
              <img :src="codeUrl" @click="getPassCode" class="login-code-img" />
            </div>
          </el-form-item>
          <div class="login-btn">
            <el-button type="primary" @click="handleLogin('loginForm')" @keyup.enter="enterSubmit()">登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  data: function () {
    return {
      loginForm: {
        username: '',
        password: '',
        uuid: '',
        code: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      },
      codeUrl: '',
      // 验证码开关
      captchaOnOff: true
    }
  },
  created () {
    this.enterSubmit()
  },
  mounted () {
    this.getPassCode()
  },
  methods: {
    async getPassCode () {
      try {
        const res = await api.getCode()
        this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff
        if (this.captchaOnOff) {
          this.codeUrl = 'data:image/gif;base64,' + res.img
          this.loginForm.uuid = res.uuid
        }
      } catch (e) {

      }
    },
    async login (p) {
      try {
        const res = await api.userLogin(p)
        if (res.code == 200) {
          if (res.communityIds == '该医生没有绑定社区id') {
            this.$message({
              message: res.communityIds,
              type: 'error'
            })
            return false
          }
          const token = res.token
          localStorage.setItem('eleToken', token)
          localStorage.setItem('roldId', res.RoleId)
          this.$message({
            message: '登录成功！',
            type: 'success'
          })
          this.$router.push({ name: 'home' })
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      } catch (err) {
        // this.$message({
        //   message: err,
        //   type: "error",
        // });
      }
    },
    enterSubmit () {
      document.onkeydown = (e) => {
        if (e.keyCode === 13) {
          // 回车后执行onSubmit事件
          this.handleLogin('loginForm')
        }
      }
    },
    handleLogin (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login(this.loginForm)
        } else {
          return false
        }
      })
    },
    isEmpty (value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
      )
    }
  }
}
</script>

<style scoped lang="less">
* {
  overflow: hidden;
}

.box {
  width: 100%;
  height: 100%;
  background-color: #3be7de;
  background-image: url("./../assets/images/bg_img.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}

.logo_box {
  margin-top: 1.25rem;
}

.m-2-1 {
  /* text-align: right; */
  position: absolute;
  top: 30%;
  left: 50%;
  width: 20%;
  transform: translate(-50%);
}

.login_box {
  width: 100%;
  border-radius: .3125rem;
  margin: 0 auto;
  background-color: rgb(0, 184, 184);
}

.ms-title {
  width: 100%;
  line-height: 3.125rem;
  text-align: center;
  font-size: 1.25rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: .0625rem solid #3be7de;
}

.ms-title img {
  width: 1.5rem;
  margin-right: .3125rem;
}

.ms-content {
  padding: 1.875rem 1.875rem;
}

.login-btn {
  text-align: center;
}

.login-btn button {
  width: 100%;
  margin-bottom: .625rem;
  height: 2.5rem;
  font-size: 1.125rem;
}

.login-tips a {
  color: #fff;
  text-decoration: underline;
}

.login-tips a:last-child {
  margin-left: 1.25rem;
}

/deep/ .el-input-group__prepend {
  background-color: rgba(59, 231, 222, 0.5);
  border: .0625rem solid #00b8b8;
  border-right: 0;
  height: 2.5rem;
  font-size: 1rem;
  color: #fff;
}

/deep/ .el-form-item {
  overflow: visible !important;
}

/deep/.el-input--small .el-input__inner {
  height: 2.5rem;
  font-size: 1rem;
}

.login-code {
  width: 33%;
  height: 2.5rem;
  float: right;

  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}
</style>
